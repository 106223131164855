import React, { Component } from 'react';
import Content from '../components/Content';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

class Projetos extends Component {
  render() {
    return (
      <>
        <Header />
        <main>
          <Sidebar />
          <Content>
            <h1>Em construção!!!!!</h1>
          </Content>
        </main>
      </>
    );
  }
}
export default Projetos;
