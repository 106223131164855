import React, { Component } from 'react';

class PageNotFound extends Component {
  goBackHome = () => {
    // todos os componentes renderizados atraves do Route, tem como props o history
    const { history } = this.props;
    history.push('/');
  };

  render() {
    console.log(this.props);
    return (
      <div className="page__404">
        PAGE NOT FOUND
        <button type="button" onClick={ this.goBackHome }>VOLTAR</button>
      </div>
    );
  }
}

export default PageNotFound;
